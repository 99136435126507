@import "@cs/styles";

.container {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h1,
  h2 {
    font-weight: 400;
  }

  h1 {
    margin-top: 10%;
    font-size: 26px;
  }

  h2 {
    margin-top: 5%;
    font-size: 15px;
  }

  border-radius: 12px;
  background-color: white;
  z-index: 1000;
  @include media("<tablet") {
    width: 70%;

    h2 {
      font-size: 13px;
    }
  }

  @include media("<phone") {
    width: 90%;
  }
}
